<ark-link-card [generalCard]="generalCardInfo()" [routerLink]="cardLink()" [queryParams]="cardParams()">
  <app-event-card-info [infoText]="infoText()"></app-event-card-info>

  @defer (when !!bannerImg()) {
  <picture class="banner">
    <button mat-mini-fab>
      <mat-icon>favorite_outline</mat-icon>
    </button>
    @if (bannerImg().imageKit) {
    <img class="banner-image" [ngSrc]="bannerImg().src" [height]="150" [width]="150" priority>
    <img class="banner-underlay" [ngSrc]="bannerImg().src + '?tr=w-300,h-150'" [height]="150" [width]="300" priority
      disableOptimizedSrcset>
    } @else {
    <img class="banner-image" [src]="bannerImg().src" [height]="150" priority>
    <img class="banner-underlay" [src]="bannerImg().src" priority>
    }
  </picture>
  } @placeholder {
  <!-- Todo(srevier): replace with skeleton loader -->
  <div class="banner" [style.background-color]="'gray'"></div>
  }

  <!-- <span class="info-text" *ngIf="infoText">| <i>{{this.infoText}}</i></span> -->
  <div class="content">
    <div class="upper-content">
      <p class="date">
        <mat-icon>access_time_filled</mat-icon>
        <span>{{ event().dateStart | date:'EEE MMM d, h:mm a' }}</span>
      </p>
      <div class="event-info">
        <h3>{{ (event().title || 'My Event') | titlecase }}</h3>
        <p>
          <mat-icon>map</mat-icon>
          <span>{{ event().online ? 'Online event' : event().location?.formattedAddress ?? 'No address provided' }}</span>
        </p>
      </div>
      @if (!preview()) {
        <p>
          <mat-icon>people_alt</mat-icon>
          <span>{{ numberResponded() }} {{ numberResponded() | participantAmount }}</span>
        </p>
      }
    </div>

    <div class="lower-content">
      @if (infoText() !== EventInfoText.MISSED) {
        <h4>
          <mat-icon color="primary">payments</mat-icon>
          <span>{{ costText() }}</span>
        </h4>
      }

      @if (!preview()) {
        <a mat-flat-button color="primary" [disabled]="hasRSVPed()" [routerLink]="rsvpLink()" [queryParams]="{ tab: 'rsvp' }">
          {{ hasRSVPed() ? 'RSVPed' : 'RSVP Now!' }}
        </a>
      }
    </div>
  </div>
</ark-link-card>