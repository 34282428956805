@if (infoText()) {
  <p 
    class="info-text"
    [style.color]="infoColor().color"
    [style.borderColor]="infoColor().border"
    [style.backgroundColor]="infoColor().bg"
  >
    <mat-icon inline>{{ infoIcon() }}</mat-icon>
    {{ infoText() }}
  </p>
}