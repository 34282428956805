import { inject, Injectable } from '@angular/core';
import { SrvApiService } from '@sentinels/services/api.service';
import { from, map, Observable, of, take } from 'rxjs';

const DEFAULT_IMAGE = () => 'assets/img/event-default.svg';

export interface EventImageObj {
  imageKit: boolean;
  src: string;
}

@Injectable({
  providedIn: 'root',
})
export class EventImageService {
  private api = inject(SrvApiService);

  getEventImgObj$(gameType: string, banner?: string): Observable<EventImageObj> {
    if (banner) return of({ src: banner, imageKit: false });
    if (gameType === 'Other') return of({ src: DEFAULT_IMAGE(), imageKit: false });

    return from(this.api.gameTypes.listAsync()).pipe(
      take(1),
      map((gameTypes) => {
        const type = gameTypes.find((gt) => gt.label === gameType);
        return {
          imageKit: type ? true : false,
          src: type ? `${type.item}.webp` : DEFAULT_IMAGE(),
        };
      }),
    );
  }
}
