import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'participantAmount',
  standalone: true
})
export class ParticipantAmountPipe implements PipeTransform {
  transform(value: number): string {
    return value === 1 ? 'person has responded' : `people have responded`;
  }
}
