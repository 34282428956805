import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

export enum EventInfoText {
  MISSED = 'You Missed This',
  TODAY = 'Happening Today',
  WAITLISTED = 'Waitlisted',
  ALMOST_FULL = 'Almost Full',
  CANCELLED = 'Cancelled'
};

export const EventInfoIcons = {
  [EventInfoText.MISSED]: 'event_busy',
  [EventInfoText.TODAY]: 'event_available',
  [EventInfoText.WAITLISTED]: 'warning',
  [EventInfoText.ALMOST_FULL]: 'warning',
};

export const EventInfoColors = {
  [EventInfoText.MISSED]: {
    bg: 'rgb(239, 219, 213)',
    color: 'rgb(214, 77, 31)',
    border: 'rgba(214, 77, 31, 0.3)',
  },
  [EventInfoText.TODAY]: {
    bg: 'rgb(223, 240, 216)',
    color: 'rgb(0, 150, 136)',
    border: 'rgba(0, 150, 136, 0.3)',
  },
  [EventInfoText.WAITLISTED]: {
    bg: 'rgb(255, 243, 224)',
    color: 'rgb(255, 152, 0)',
    border: 'rgba(255, 152, 0, 0.3)',
  },
  [EventInfoText.ALMOST_FULL]: {
    bg: 'rgb(255, 243, 224)',
    color: 'rgb(255, 152, 0)',
    border: 'rgba(255, 152, 0, 0.3)',
  },
  [EventInfoText.CANCELLED]: {
    bg: 'rgb(239, 219, 213)',
    color: 'rgb(214, 77, 31)',
    border: 'rgba(214, 77, 31, 0.3)',
  },
};

@Component({
  selector: 'app-event-card-info',
  standalone: true,
  imports: [
    MatIconModule,
  ],
  templateUrl: './event-card-info.component.html',
  styleUrl: './event-card-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCardInfoComponent {
  infoText = input<EventInfoText>();

  infoIcon = computed(() =>
    EventInfoIcons[this.infoText()] || 'warning',
  );

  infoColor = computed(() =>
    EventInfoColors[this.infoText()] || {
      bg: 'rgb(255, 243, 224)',
      color: 'rgb(255, 152, 0)',
      border: 'rgba(255, 152, 0, 0.3)',
    },
  );
}
