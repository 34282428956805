import { CommonModule, DatePipe, formatCurrency, IMAGE_CONFIG, NgOptimizedImage, provideImageKitLoader } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { ArkLinkCardComponent } from '@ark/components/link-card/link-card.component';
import { ParticipantAmountPipe } from '@ark/pipes/participant-amount.pipe';
import { EventJoiner } from '@index/interfaces';
import { Store } from '@ngrx/store';
import { GthEventItemModel } from '@sentinels/models';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { SportIconModule } from '@shared/components/sport-icon/sport-icon.module';
import { APP_ROUTES } from '@shared/helpers';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { switchMap } from 'rxjs';

import { APP_STATE } from '../../../../../../../gth-legacy/src/public-api';
import { EventImageService } from '../../services/event-image/event-image.service';
import { EventCardInfoComponent, EventInfoText } from './event-card-info/event-card-info.component';

const mockEvent = {
  id: '1',
  gameType: 'soccer',
  banner: 'assets/img/event-default.svg',
  title: 'Gametime Hero Event',
  dateStart: new Date(),
  location: {
    formattedAddress: '123 Main St, Sampletown USA',
  },
};

const GENERAL_CARD_INFO = {
  photoURL: 'assets/img/baseball.svg',
  title: 'Discover more events<br/>near you!',
};

const TEAM_CARD_INFO = {
  photoURL: 'assets/img/baseball.svg',
  title: 'Create an event for your community!',
};

@Component({
  selector: 'app-event-card',
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    SportIconModule,
    MatBadgeModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    NgOptimizedImage,
    DatePipe,
    RouterLink,
    ArkLinkCardComponent,
    ParticipantAmountPipe,
    EventCardInfoComponent,
  ],
  standalone: true,
  providers: [
    {
      provide: IMAGE_CONFIG,
      useValue: {
        placeholderResolution: 50,
      },
    },
    provideImageKitLoader('https://ik.imagekit.io/67ng46hjy'),
  ],
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCardComponent {
  private eventImageService = inject(EventImageService);
  private store = inject(Store<APP_STATE>);
  private routes = APP_ROUTES;

  get EventInfoText() {
    return EventInfoText;
  }

  numberResponded = input<number>(0);
  numberNeeded = input<number>(0);
  event = input<GthEventItemModel>(mockEvent as GthEventItemModel);
  participants = input<EventJoiner[]>([]);
  preview = input<boolean>(false);

  teamId = input<string>('');
  generalCard = input<boolean>(false);
  teamCard = input<boolean>(false);
  generalCardInfo = computed(() => {
    if (this.generalCard()) {
      return GENERAL_CARD_INFO;
    }

    if (this.teamCard()) {
      return TEAM_CARD_INFO;
    }
    return null;
  });

  user = this.store.selectSignal(selectUser);

  hasRSVPed = computed(() => this.participants()?.some((p) => p.player === this.user()?.uid));
  eventIsFull = computed(() => this.numberNeeded() === 0 ?
    false :
    this.numberResponded() >= this.numberNeeded(),
  );
  eventIsAmostFull = computed(() => this.numberResponded() >= this.numberNeeded() * 0.8);

  cardLink = computed(() => {
    if (this.generalCard()) return this.routes.DiscoverGames;
    if (this.teamCard()) return this.routes.CreateGame;
    if (this.preview()) return undefined;
    return ['/evt/', this.event().id];
  });

  cardParams = computed(() => {
    if (this.teamCard() && this.teamId()) {
      return { teamId: this.teamId() };
    }
    return { context: 'events' };
  });

  costText = computed(() => {
    return this.event().cost == null ? 'Free!' : formatCurrency(this.event().cost, 'en', '$');
  });

  // Banner image & color computes
  public bannerImg = toSignal(toObservable(this.event).pipe(switchMap((event) =>
    this.eventImageService.getEventImgObj$(event.gameType, event.banner),
  )));

  infoText = computed(() =>
    this.getInfoText(this.event().dateStart),
  );

  rsvpLink = computed<string[]>(() =>
    this.event() ? ['/evt/', this.event().id] : [''],
  );

  getInfoText(dateStart: Date): EventInfoText {
    if (this.dateHasPassed(dateStart)) return EventInfoText.MISSED;
    if (this.dateIsToday(dateStart)) return EventInfoText.TODAY;
    if (this.eventIsFull()) return EventInfoText.WAITLISTED;
    if (this.eventIsAmostFull()) return EventInfoText.ALMOST_FULL;
    if (this.event().cancelled) return EventInfoText.CANCELLED;
    return undefined;
  }

  dateHasPassed(date: Date) {
    const today = new Date();
    return date.getTime() < today.getTime();
  }

  dateIsToday(date: Date) {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }
}
